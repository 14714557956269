import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  private readonly siteHistory: Array<string> = [];

  constructor(
    private location: Location,
    private router: Router
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.siteHistory.push(event.urlAfterRedirects);
      }
    });
  }

  back(defaultPage?: string | Array<string>): void {
    this.siteHistory.pop();
    if (this.siteHistory.length > 0) {
      this.location.back();
    } else if (defaultPage) {
      if (!Array.isArray(defaultPage)) {
        defaultPage = [defaultPage];
      }
      this.router.navigate(defaultPage).catch(console.error);
    } else {
      this.router.navigateByUrl('/').catch(console.error);
    }
  }
}
