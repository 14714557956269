<div
  (mousedown)="onMousedown()"
  (mouseup)="onMouseup()"
  (touchend)="onMouseup()"
  (touchstart)="onMousedown()"
  [ngClass]="mode"
  [style.--solid-color]="solidColor"
  class="scroll-button">
  @if (mode === 'left') {
    <mat-icon class="icon"> chevron_left</mat-icon>
  }

  @if (mode === 'right') {
    <mat-icon class="icon"> navigate_next</mat-icon>
  }
</div>
